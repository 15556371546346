
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "card-3",
  components: {},
  props: {
    cardClasses: String,
    id: String,
    avatar: String,
    online: Boolean,
    status: String,
    initials: String,
    color: String,
    name: String,
    position: String,
    phone: String,
    avgEarnings: Number,
    totalSales: Number,
    connected: Boolean,
    method: { type: Function }
  },
  methods: {
    async suspend(id){
      useToast().info('Suspending, please wait...')
      this.axios.post('/users/suspend', {
        uid: id
      }).then(({data})=>{
        useToast().success(data.message)
        this.$emit('refresh', true)
        // this.refresh()
      }).catch(e=>{
        try{
          useToast().error(e.response.data.message)
        }catch(err){
          useToast().warning(e.message)
        }
      })

    },
    async verify(id){
      useToast().info('Reactiving, please wait...')
      this.axios.post('/users/activate', {
        uid: id
      }).then(({data})=>{
        useToast().success(data.message)
        this.$emit('refresh', true)
        // this.refresh()
      }).catch(e=>{
        try{
          useToast().error(e.response.data.message)
        }catch(err){
          useToast().warning(e.message)
        }
      })
    },
    async credit(id){
      useToast().info('Creding user airtime, please wait...')
      this.axios.post('/users/credit', {
        uid: id
      }).then(({data})=>{
        useToast().success(data.message)
        this.$emit('refresh', true)
        // this.refresh()
      }).catch(e=>{
        try{
          useToast().error(e.response.data.message)
        }catch(err){
          useToast().warning(e.message)
        }
      })
    }
  }
});
